.framePage .formTitle .el-icon-plus:before {
  content: "\e6d9";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.framePage .formTitle .el-cascader--small {
  line-height: 40px;
}
.framePage .formTitle h3 {
  display: flex;
  border-left: 3px solid #5c6be8;
  margin-bottom: 30px;
}
.framePage .formTitle h3 span {
  margin-left: 20px;
}
.framePage .framePage-body .form-box {
  padding: 10px 20px;
}
.framePage .framePage-body .form-box .el-form {
  padding: 20px;
}
.framePage .framePage-body .btn-box {
  width: 43.75rem;
  display: flex;
  text-align: center;
  justify-content: space-evenly;
}
.framePage .framePage-body .gezhongshoufei {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}
.framePage /deep/ .noMargin .el-form-item__content {
  margin-left: 10px !important;
}
.framePage /deep/ .el-upload {
  border: none !important;
}
 /deep/ .upload-btns .el-upload {
  height: 40px !important;
  border: none !important;
}
