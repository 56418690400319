





































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.framePage {
  .formTitle {
    .el-icon-plus:before {
      content: "\e6d9";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .el-cascader--small {
      line-height: 40px;
    }

    h3 {
      display: flex;
      border-left: 3px solid rgb(92, 107, 232);
      margin-bottom: 30px;

      span {
        margin-left: 20px;
      }
    }
  }

  .framePage-body {
    .form-box {
      padding: 10px 20px;

      .el-form {
        padding: 20px;
      }
    }

    .btn-box {
      width: 43.75rem;
      display: flex;
      text-align: center;
      justify-content: space-evenly;
    }

    .gezhongshoufei {
      display: flex;
      flex-direction: column;
      margin-bottom: 1rem;
    }
  }

  /deep/.noMargin .el-form-item__content {
    margin-left: 10px !important;
  }

  /deep/.el-upload {
    border: none !important;
  }
}

/deep/.upload-btns .el-upload {
  height: 40px !important;
  border: none !important;
}
